// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-couple-js": () => import("./../../../src/pages/couple.js" /* webpackChunkName: "component---src-pages-couple-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-gift-registry-js": () => import("./../../../src/pages/gift-registry.js" /* webpackChunkName: "component---src-pages-gift-registry-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-layout-cache-js": () => import("./../../../src/pages/layoutCache.js" /* webpackChunkName: "component---src-pages-layout-cache-js" */),
  "component---src-pages-our-story-js": () => import("./../../../src/pages/our-story.js" /* webpackChunkName: "component---src-pages-our-story-js" */),
  "component---src-pages-photo-divider-js": () => import("./../../../src/pages/photo-divider.js" /* webpackChunkName: "component---src-pages-photo-divider-js" */),
  "component---src-pages-where-when-js": () => import("./../../../src/pages/where-when.js" /* webpackChunkName: "component---src-pages-where-when-js" */)
}

